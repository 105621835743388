import React, { Component } from 'react'

class renderSelect extends Component{

  constructor(props) {
    super(props);
    this.toggleClass = this.toggleClass.bind(this);
    this.state = {
        value : "",
        active: false,
    };
  }
  toggleClass(){
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }

  render(){
    const { input, label, infoText, disabled, options, customClass, meta: { touched, error } } = this.props
    
    
    return(
      <div className={customClass}>
        <label  dangerouslySetInnerHTML={{__html:label}}></label>
        {infoText != undefined && <div className={this.state.active ? 'info-trigger info-show': 'info-trigger'}><span onMouseEnter={this.toggleClass} onMouseLeave={this.toggleClass} >i</span><div className="info-message" dangerouslySetInnerHTML={{__html:infoText}}></div></div>}
        <div className="wizard-input-container">
          <select {...input} disabled={disabled}>
          {Object.entries(options).map( ([key, value],i) => <option key={key} value={key} >{value}</option>)}
            </select>
          <div className="error-message">{touched && error && <span>{error}</span>}</div>
        </div>
      </div>
      )
  }
}

export default renderSelect