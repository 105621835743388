import React, { Component } from 'react'

class renderField extends Component{

  constructor(props) {
    super(props);
    this.toggleClass = this.toggleClass.bind(this);
    this.state = {
        value : "",
        active: false,
    };
  }
  toggleClass(){
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }
  render(){
    const { input, label, init, infoText, step, customClass = '', disabled, type, meta: { touched, error } } = this.props
    
    
    return(
    <div className={disabled ? "disabled-field " + customClass : customClass}>
      <div className="label-wrapper">
        <label dangerouslySetInnerHTML={{__html:label}}></label>
        {infoText != undefined && <div className={this.state.active ? 'info-trigger info-show': 'info-trigger'}><span onMouseEnter={this.toggleClass} onMouseLeave={this.toggleClass} >i</span><div className="info-message" dangerouslySetInnerHTML={{__html:infoText}}></div></div>}
      </div>
      <div className="wizard-input-container">
        <input {...input} disabled={disabled ? 'disabled' : false} step={step == undefined ? '' : step } type={type}/>
      </div>
      
      <div className="error-message">{touched && error && error.split("||").map((x,i) => <span key={i}>{x}</span>)}</div>
    </div>
    )
  }
}

export default renderField