import React from 'react'
import { Field, reduxForm } from 'redux-form'
import validate from './validate'
import normalization from './normalization'
import renderField from './renderField'
import renderSelect from './renderSelect'
import renderTextarea from './renderTextarea'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Image2 from '../../images/osees_image2.jpg'


import { connect } from 'react-redux'
let WizardFormForthPage = (props, ) => {
    const { handleSubmit, pristine, previousPage, submitting } = props

    const normRules = normalization().rules
    const normAll = normalization().normalizeAll
    const arithmos_anoigmaton = 2;
    
    let currentVathro = 0;
    function dvalues() {
        if ( typeof props.value.wizard.values.component_threshold !== 'undefined'  && props.value.wizard.values.component_threshold == 1 ) {
            //user-defined
            console.log('user defined')
            props.disabledFields['d1a'] = false;
            props.disabledFields['d2a'] = false;
            props.disabledFields['d3a'] = false;
            props.disabledFields['d4a'] = false;
            
            
        }else{
            console.log('default')
            //default
            props.disabledFields['d1a'] = true;
            props.value.wizard.values['d1a'] = 0.1
            props.disabledFields['d2a'] = true;
            props.value.wizard.values['d2a'] = 0.2
            props.disabledFields['d3a'] = true;
            props.value.wizard.values['d3a'] = 0.3
            props.disabledFields['d4a'] = true;
            props.value.wizard.values['d4a'] = 0.5
        }
    }

    function nextVathro() {
        if ( currentVathro < arithmos_anoigmaton + 1 ) {
            document.getElementById(`button_step4_vathro_${currentVathro}`).style.display = 'none';
            currentVathro += 1;
            document.getElementById(`step4_vathro_${currentVathro}`).style.display = 'block';
            
        }

    }
    
    return (
        <form onSubmit={handleSubmit}>
            
            <div className="flex-col-new">
            <div className="abutment_mass" id="abutment_mass">
                <Field
                name='field_4_abutment_mass'
                type="text"
                component={renderField}
                label="Abutment Mass (tn)"     
                normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
            />
            </div>
            <div className="d_values">
                <h4>Abutment Threshold Values</h4>
                <Field
                    name="d1a"     
                    type="text"
                    component={renderField}
                    label="d1A (m)"
                    onChange={dvalues()}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d1a}
                    infoText="Abutment Threshold Values (LS1) - Must be positive value, up to 3 decimals"
                />
                <Field
                    name="d2a"     
                    type="text"
                    component={renderField}
                    label="d2Α (m)"
                    onChange={dvalues()}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d2a}
                    infoText="Abutment Threshold Values (LS2) - Must be positive value, up to 3 decimals"
                />
                <Field
                    name="d3a"     
                    type="text"
                    component={renderField}
                    label="d3Α (m)"
                    onChange={dvalues()}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d3a}
                    infoText="Abutment Threshold Values (LS3) - Must be positive value, up to 3 decimals"
                />
                <Field
                    name="d4a"     
                    type="text"
                    component={renderField}
                    label="d4Α (m)"
                    onChange={dvalues()}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d4a}
                    infoText="Abutment Threshold Values (LS4) - Must be positive value, up to 3 decimals"
                />
            </div>
            </div>
            <div className="images-col imagestep2">
            <a href={Image2} target="_blank"><img src={Image2} alt="The Bridge Database" /></a>
            </div>
            
            {[...Array(arithmos_anoigmaton)].map((_, i) => {
                return (
                <>
                <div  className={i <= arithmos_anoigmaton+1 && i != 0 ? "wizard-step2-group hide-this" : "wizard-step2-group"} id={`step4_vathro_${i}`}>
                    <div className="group-header"><h3>Abutment #{i + 1} { i == 0  ? "(Left)" : "(Right)"} </h3></div>
                    <div className="flex-col">
                    <div className="flex-col-1">
                    <Field
                        name={`field_4_1_${i}`}
                        type="text"
                        component={renderField}
                        label="1. K<sub>abtm,x</sub> (kN/m)"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        infoText="Abutment stiffness at the longitudinal (x) direction"
                    />
                    <Field
                        name={`field_4_2_${i}`}
                        type="text"
                        component={renderField}
                        label="2. K<sub>abtm,y</sub> (kN/m)"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        infoText="Abutment stiffness at the transverse (y) direction calculated according to Caltrans"
                    />
                    <Field
                        name={`field_4_3_${i}`}
                        type="text"
                        component={renderField}
                        label="3. Backwall height (m)"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    />
                </div>
                <div className="flex-col-2">
                    <Field
                        name={`field_4_4_${i}`}
                        type="text"
                        component={renderField}
                        label="4. Gap<sub>x</sub> (m)"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    />
                    <Field
                        name={`field_4_5_${i}`}
                        type="text"
                        component={renderField}
                        label="5. Gap<sub>y</sub> (m)"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    />

                    <Field
                        name={`field_4_6_${i}`}
                        component={renderSelect}
                        options={{"1":"Cohesive", "2":"Cohesionless"}}
                        label="6. Embankment Soil Type"
                    />
                </div>
               </div>
               { i < arithmos_anoigmaton - 1  && <div className="flex-col buttons-step"><button id={`button_step4_vathro_${i}`} type="button" onClick={nextVathro}>Proceed to Abutment #{i + 2} (Right)</button></div>}
               </div>
               
               </>
               
                )
                
            }
            
            )
        }
        
       
       <div className="buttons-main">
            <button type="button" className="previous" onClick={previousPage}><FontAwesomeIcon icon={faChevronLeft} /> Back to Bearing Properties </button>
            <button type="submit" className="next" disabled={!props.valid}>
                Calculate
            </button>
        </div>
        </form>
    )
}

function mapStateToProps(state) {
    return { value: state.form, disabledFields: {} };
}

WizardFormForthPage = connect(mapStateToProps)(WizardFormForthPage)

export default reduxForm({
  form: 'wizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  initialValues: {}
})(WizardFormForthPage)