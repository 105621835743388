const normalize = () => {

    const norm = {}
    
    function isNumber(x) {
        return !isNaN(x) && x.trim() != "";
    }

    norm.normalizeAll = (normalizers) => {
            return function(value , previousValue , allValues , previousAllValues){ //note that these arguments are passed by default from redux form
                var i = 0;
                var normalizersLength = normalizers.length;
                var currentValue = value;
                while(i < normalizersLength)
                {
                    var currentNormalizer = normalizers[i];
                    if(typeof currentNormalizer == "function")
                    {
                        currentValue = currentNormalizer(currentValue ,previousValue , allValues , previousAllValues);
                    }
                    i++;
                }

                return currentValue;
            }
        }
    norm.rules = {}

    norm.rules.noSpaces = value => value && value.replace(" ", "");
    norm.rules.noCommas = value => value && value.replace(",", ".");
    norm.rules.noDuplicatedSymbols = value => value && value.replace("..", ".").replace(";;", ";");
    norm.rules.integerOnly = value => value && parseInt(value);
    norm.rules.maxTwoDecimals = value => value && Math.floor(parseFloat(value) * 100 / 100);
    norm.rules.numbersOnly = value => value && value.split('').map( x => (isNumber(x) || x === '.' || x === ';') ? x : '');
    return norm
  }


export default normalize