import React from 'react'
import { Field, reduxForm } from 'redux-form'
import validate from './validate'
import normalization from './normalization'
import renderField from './renderField'
import renderSelect from './renderSelect'
import renderTextarea from './renderTextarea'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Image2 from '../../images/osees_image2.jpg'
import { render } from 'react-dom'


let WizardFormThirdPage = (props, values, state) => {
    const { handleSubmit, previousPage } = props

    const normRules = normalization().rules
    const normAll = normalization().normalizeAll
    const arithmos_anoigmaton = props.value.wizard.values.arithmos_anoigmaton - 1 + 2;
    
    let currentVathro = 0;
    

    function nextVathro() {
        if ( currentVathro < arithmos_anoigmaton  ) {
            document.getElementById(`button_step3_vathro_${currentVathro}`).style.display = 'none';
            currentVathro += 1;
            document.getElementById(`step3_vathro_${currentVathro}`).style.display = 'block';
            
        }

    }
    function dvalues() {
        if ( typeof props.value.wizard.values.component_threshold !== 'undefined'  && props.value.wizard.values.component_threshold == 1 ) {
            //user-defined
            props.disabledFields['d1b'] = false;
            props.disabledFields['d2b'] = false;
            props.disabledFields['d3b'] = false;
            props.disabledFields['d4b'] = false;
            
            
        }else{
            //default
            props.disabledFields['d1b'] = true;
            props.value.wizard.values['d1b'] = 0.1
            props.disabledFields['d2b'] = true;
            props.value.wizard.values['d2b'] = 0.2
            props.disabledFields['d3b'] = true;
            props.value.wizard.values['d3b'] = 0.3
            props.disabledFields['d4b'] = true;
            props.value.wizard.values['d4b'] = 0.5
        }
    }

    // function init_values() {
    //     // for (var i = 0; i < arithmos_anoigmaton; i++) {
    //     //     if(i == 0 || i == (arithmos_anoigmaton - 1)){
    //     //         props.value.wizard.values[`field_3_1_${i}`] = '350000.00'
    //     //         props.value.wizard.values[`field_3_2_${i}`] = '11700.25'
    //     //         props.value.wizard.values[`field_3_3_${i}`] = '11700.25'
    //     //         props.value.wizard.values[`field_3_4_${i}`] = '1000000'
    //     //         props.value.wizard.values[`field_3_5_${i}`] = '35000.00'
    //     //         props.value.wizard.values[`field_3_6_${i}`] = '35000.00'
    //     //         props.value.wizard.values[`field_3_7_${i}`] = '1'
    //     //         props.value.wizard.values[`field_3_8_${i}`] = '0.15'
    //     //     }else{
    //     //         props.value.wizard.values[`field_3_1_${i}`] = '700000.00'
    //     //         props.value.wizard.values[`field_3_2_${i}`] = '23000.00'
    //     //         props.value.wizard.values[`field_3_3_${i}`] = '23000.00'
    //     //         props.value.wizard.values[`field_3_4_${i}`] = '1000000'
    //     //         props.value.wizard.values[`field_3_5_${i}`] = '16000.00'
    //     //         props.value.wizard.values[`field_3_6_${i}`] = '16000.00'
    //     //         props.value.wizard.values[`field_3_7_${i}`] = '1'
    //     //         props.value.wizard.values[`field_3_8_${i}`] = '0.15'
    //     //     }  
    //     // } 

    // }
    // init_values();




    function thirdstep(i){
        //console.log(i);
        if(i == 0 || i == (arithmos_anoigmaton-1)){
            // First or Last one -> Enable Fields');
            props.disabledFields[`field_3_1_${i}`] = false;
            props.disabledFields[`field_3_2_${i}`] = false;
            props.disabledFields[`field_3_3_${i}`] = false;
            props.disabledFields[`field_3_4_${i}`] = false;
            props.disabledFields[`field_3_5_${i}`] = false;
            props.disabledFields[`field_3_6_${i}`] = false;
            props.disabledFields[`field_3_7_${i}`] = false;
            props.disabledFields[`field_3_8_${i}`] = false;
            console.log(props.value.wizard.values[`field_3_1_${i}`])
            if(props.value.wizard.values[`field_3_1_${i}`] == undefined){
                props.value.wizard.values[`field_3_1_${i}`] = '350000.00'
            }
            if(props.value.wizard.values[`field_3_2_${i}`] == undefined){
                props.value.wizard.values[`field_3_2_${i}`] = '11700.25'
            }
            if(props.value.wizard.values[`field_3_3_${i}`] == undefined){
                props.value.wizard.values[`field_3_3_${i}`] = '11700.25'
            }
            if(props.value.wizard.values[`field_3_4_${i}`] == undefined){
                props.value.wizard.values[`field_3_4_${i}`] = '1000000'
            }
            if(props.value.wizard.values[`field_3_5_${i}`] == undefined){
                props.value.wizard.values[`field_3_5_${i}`] = '35000.00'
            }
            if(props.value.wizard.values[`field_3_6_${i}`] == undefined){
                props.value.wizard.values[`field_3_6_${i}`] = '35000.00'
            }
            if(props.value.wizard.values[`field_3_7_${i}`] == undefined){
                props.value.wizard.values[`field_3_7_${i}`] = '1'
            }
            if(props.value.wizard.values[`field_3_8_${i}`] == undefined){
                props.value.wizard.values[`field_3_8_${i}`] = '0.15'
            }
        }else{
            // Check value of Pier (i -1)
            //console.log('Type field '+i+':'+typeof props.value.wizard.values[`field_2_sindesi_vathron_katastromatos_${i-1}`]);
            //console.log('Value field '+i+':'+props.value.wizard.values[`field_2_sindesi_vathron_katastromatos_${i-1}`]);
            if(props.value.wizard.values[`field_2_sindesi_vathron_katastromatos_${i-1}`] == 1){
                //Monolithic -> Disable Fields
                props.disabledFields[`field_3_1_${i}`] = true;
                props.disabledFields[`field_3_2_${i}`] = true;
                props.disabledFields[`field_3_3_${i}`] = true;
                props.disabledFields[`field_3_4_${i}`] = true;
                props.disabledFields[`field_3_5_${i}`] = true;
                props.disabledFields[`field_3_6_${i}`] = true;
                props.disabledFields[`field_3_7_${i}`] = true;
                props.disabledFields[`field_3_8_${i}`] = true;
            }else if(props.value.wizard.values[`field_2_sindesi_vathron_katastromatos_${i-1}`] == 2){
                //Bearing -> Enable Fields
                props.disabledFields[`field_3_1_${i}`] = false;
                props.disabledFields[`field_3_2_${i}`] = false;
                props.disabledFields[`field_3_3_${i}`] = false;
                props.disabledFields[`field_3_4_${i}`] = false;
                props.disabledFields[`field_3_5_${i}`] = false;
                props.disabledFields[`field_3_6_${i}`] = false;
                props.disabledFields[`field_3_7_${i}`] = false;
                props.disabledFields[`field_3_8_${i}`] = false;
            }else{
                //Undefined -> User Did not choose -> Monolithic (Default Option) -> Disable Fields
                //console.log('else');
                props.disabledFields[`field_3_1_${i}`] = true;
                props.disabledFields[`field_3_2_${i}`] = true;
                props.disabledFields[`field_3_3_${i}`] = true;
                props.disabledFields[`field_3_4_${i}`] = true;
                props.disabledFields[`field_3_5_${i}`] = true;
                props.disabledFields[`field_3_6_${i}`] = true;
                props.disabledFields[`field_3_7_${i}`] = true;
                props.disabledFields[`field_3_8_${i}`] = true;
            }
            if(props.value.wizard.values[`field_3_1_${i}`] == undefined){
                props.value.wizard.values[`field_3_1_${i}`] = '700000.00'
            }
            if(props.value.wizard.values[`field_3_2_${i}`] == undefined){
                props.value.wizard.values[`field_3_2_${i}`] = '23000.00'
            }
            if(props.value.wizard.values[`field_3_3_${i}`] == undefined){
                props.value.wizard.values[`field_3_3_${i}`] = '23000.00'
            }
            if(props.value.wizard.values[`field_3_4_${i}`] == undefined){
                props.value.wizard.values[`field_3_4_${i}`] = '1000000'
            }
            if(props.value.wizard.values[`field_3_5_${i}`] == undefined){
                props.value.wizard.values[`field_3_5_${i}`] = '16000.00'
            }
            if(props.value.wizard.values[`field_3_6_${i}`] == undefined){
                props.value.wizard.values[`field_3_6_${i}`] = '16000.00'
            }
            if(props.value.wizard.values[`field_3_7_${i}`] == undefined){
                props.value.wizard.values[`field_3_7_${i}`] = '1'
            }
            if(props.value.wizard.values[`field_3_8_${i}`] == undefined){
                props.value.wizard.values[`field_3_8_${i}`] = '0.15'
            }
        }
        
    }
    
    [...Array(arithmos_anoigmaton)].map((_, i) => {thirdstep(i)})
    return (
        <form onSubmit={handleSubmit}>
            
            <div className="flex-col-new">
            <div className="d_values">
            <h4>Bearing Threshold Values</h4>
                <Field
                    name="d1b"     
                    type="text"
                    component={renderField}
                    onChange={dvalues()}
                    label="d1B (m)"
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d1b}
                    infoText="Bearing Threshold Values (LS1) - Must be positive value, up to 3 decimals"
                />
                <Field
                    name="d2b"     
                    type="text"
                    component={renderField}
                    onChange={dvalues()}
                    label="d2B (m)"
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d2b}
                    infoText="Bearing Threshold Values (LS2) - Must be positive value, up to 3 decimals"
                />
                <Field
                    name="d3b"     
                    type="text"
                    component={renderField}
                    onChange={dvalues()}
                    label="d3B (m)"
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d3b}
                    infoText="Bearing Threshold Values (LS3) - Must be positive value, up to 3 decimals"
                />
                <Field
                    name="d4b"     
                    type="text"
                    component={renderField}
                    onChange={dvalues()}
                    label="d4B (m)"
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d4b}
                    infoText="Bearing Threshold Values (LS4) - Must be positive value, up to 3 decimals"
                />
            </div>
            </div>
            <div className="images-col imagestep2">
            <a href={Image2} target="_blank"><img src={Image2} alt="The Bridge Database" /></a>
            </div>
            {[...Array(arithmos_anoigmaton)].map((_, i) => {
                return <div key={i} className={i <= arithmos_anoigmaton+2 && i != 0 ? "wizard-step2-group hide-this" : "wizard-step2-group"} id={`step3_vathro_${i}`}>
                    <div className="group-header"><h3>Bearings {i == 0 && '(Left Abutment)'}{i+1 == arithmos_anoigmaton && '(Right Abutment)'}{(i != 0 && i+1 != arithmos_anoigmaton)  && `(Pier ${i})`}</h3> {(props.value.wizard.values[`field_2_sindesi_vathron_katastromatos_${i-1}`] != 2 && i != 0 && i != arithmos_anoigmaton -1) && '(Monolithic Connection)'}</div>
                    <div className="flex-col">
                    <div className="flex-col-1">
                    <Field
                    name={`field_3_1_${i}`}
                    type="text"
                    init="354"
                    component={renderField}
                    label={`${i+1}.1 Κ<sub>v</sub> (kN/m)`}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    onChange={thirdstep(i)}
                    disabled={props.disabledFields[`field_3_1_${i}`]}
                    infoText={`Vertical Stiffness (Sum of all bearings at the ${(i == 0 || i+1 == arithmos_anoigmaton) ? 'abutment location' : 'pier-to-deck support'})`}
                />
                <Field
                    name={`field_3_2_${i}`}
                    type="text"
                    init="354"
                    component={renderField}
                    label={`${i+1}.2 K<sub>h,x</sub> (kN/m)`}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    onChange={thirdstep(i)}
                    disabled={props.disabledFields[`field_3_2_${i}`]}
                    infoText={`Horizontal Stiffness at the longitudinal (x) direction (Sum of all bearings at the ${(i == 0 || i+1 == arithmos_anoigmaton) ? 'abutment location' : 'pier-to-deck support'})`}
                />
                <Field
                    name={`field_3_3_${i}`}
                    type="text"
                    init="354"
                    component={renderField}
                    label={`${i+1}.3 Κ<sub>h,y</sub> (kN/m)`}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    onChange={thirdstep(i)}
                    disabled={props.disabledFields[`field_3_3_${i}`]}
                    infoText={`Horizontal Stiffness at the longitudinal (y) direction (Sum of all bearings at the ${(i == 0 || i+1 == arithmos_anoigmaton) ? 'abutment location' : 'pier-to-deck support'})`}
                />
                <Field
                    name={`field_3_4_${i}`}
                    type="text"
                    init="354"
                    component={renderField}
                    label={`${i+1}.4 Κ<sub>b,z</sub> (kNm/rad)`}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    onChange={thirdstep(i)}
                    disabled={props.disabledFields[`field_3_4_${i}`]}
                    infoText={`Torsional Stiffness (Sum of all bearings at the ${(i == 0 || i+1 == arithmos_anoigmaton) ? 'abutment location' : 'pier-to-deck support'})`}
                />
                </div>
                <div className="flex-col-2">
                <Field
                    name={`field_3_5_${i}`}
                    type="text"
                    init="354"
                    component={renderField}
                    label={`${i+1}.5 Κ<sub>b,x</sub> (kNm/rad)`}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    onChange={thirdstep(i)}
                    disabled={props.disabledFields[`field_3_5_${i}`]}
                    infoText={`Rotational Stiffness at the longitudinal (x) direction (Sum of all bearings at the ${(i == 0 || i+1 == arithmos_anoigmaton) ? 'abutment location' : 'pier-to-deck support'})`}
                />
                <Field
                    name={`field_3_6_${i}`}
                    type="text"
                    init="354"
                    component={renderField}
                    label={`${i+1}.6 Κ<sub>b,y</sub> (kNm/rad)`}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    onChange={thirdstep(i)}
                    disabled={props.disabledFields[`field_3_6_${i}`]}
                    infoText={`Rotational Stiffness at the longitudinal (y) direction (Sum of all bearings at the ${(i == 0 || i+1 == arithmos_anoigmaton) ? 'abutment location' : 'pier-to-deck support'})`}
                />
                <Field
                    name={`field_3_7_${i}`}
                    component={renderSelect}
                    init="1"
                    options={{"1":"Elastomeric", "2":"Lead Rubber", "3":"Pot"}}
                    label={`${i+1}.7 Bearing Type`}
                    onChange={thirdstep(i)}
                    disabled={props.disabledFields[`field_3_7_${i}`]}
                /> 
                <Field
                    name={`field_3_8_${i}`}
                    type="text"
                    init="354"
                    component={renderField}
                    label={`${i+1}.8 Bearing Thickness (m)`}
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])} 
                    onChange={thirdstep(i)}
                    disabled={props.disabledFields[`field_3_8_${i}`]}
                    infoText="Bearing Thickness (m) : tr : total elastomer height"
                />
                </div>
               </div>
               { i < arithmos_anoigmaton - 1 && <div className="flex-col buttons-step"><button id={`button_step3_vathro_${i}`} type="button" onClick={nextVathro}>Proceed to Bearing #{i + 2} from {arithmos_anoigmaton} in total</button></div>}

                </div>
            })
        }

        <div className="buttons-main">
            <button type="button" className="previous" onClick={previousPage}><FontAwesomeIcon icon={faChevronLeft} /> Back to Pier Properties </button>
            <button type="submit" className="next" disabled={props.invalid}>Proceed to Abutment Properties <FontAwesomeIcon icon={faChevronRight} /></button>
        </div>
        </form>
    )
}

function mapStateToProps(state) {
    return { value: state.form, disabledFields: {} };
}

WizardFormThirdPage = connect(mapStateToProps)(WizardFormThirdPage)

export default reduxForm({
  form: 'wizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  initialValues: {
      
  }
})(WizardFormThirdPage)