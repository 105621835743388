import React from 'react'
import { Field, reduxForm } from 'redux-form'
import validate from './validate'
import normalization from './normalization'
import renderField from './renderField'
import renderSelect from './renderSelect'
import renderTextarea from './renderTextarea'
import { formValues } from 'redux-form' // ES6
import WizardFormFirstPage from './WizardFormFirstPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import Image2 from '../../images/osees_image2.jpg'


let WizardFormSecondPage = props => {
    const { handleSubmit, previousPage } = props

    const normRules = normalization().rules
    const normAll = normalization().normalizeAll
    const arithmos_anoigmaton = props.value.wizard.values.arithmos_anoigmaton - 1;
    let currentVathro = 0;
    
    

    function nextVathro() {
        if ( currentVathro < arithmos_anoigmaton - 1 ) {
            document.getElementById(`button_step2_vathro_${currentVathro}`).style.display = 'none';
            currentVathro += 1;
            document.getElementById(`step2_vathro_${currentVathro}`).style.display = 'block';
            
        }

    }
    

    function pierType() {
        //console.log(props.value.wizard.values['pier_type']);
        // if (props.value.wizard.values['pier_type'] == '2'){
        //     props.value.wizard.values['polistila_arithmos_stilon'] = 4
        //     props.value.wizard.values['polistila_apostasis_stilon'] = '1:1:1'
        // }

        if ( typeof props.value.wizard.values['pier_type'] == 'undefined' || props.value.wizard.values['pier_type'] != '2' ) {
            props.disabledFields['polistila_arithmos_stilon'] = false
            props.value.wizard.values['polistila_arithmos_stilon'] = 4
            props.disabledFields['polistila_apostasis_stilon'] = false
            props.value.wizard.values['polistila_apostasis_stilon'] = '1;1;1'
        } else {
            props.disabledFields['polistila_arithmos_stilon'] = true
            props.value.wizard.values['polistila_arithmos_stilon'] = 1
            props.disabledFields['polistila_apostasis_stilon'] = true
            props.value.wizard.values['polistila_apostasis_stilon'] = 1
            
        }

    }

    function connectionType(i) {
        if(props.value.wizard.values['sindesi_vathron_katastromatos'] == 1){
            console.log('Value 1');
            //Monolithic All. Add Value 1 and Disable Field for every Pier
            props.disabledFields[`field_2_sindesi_vathron_katastromatos_${i}`] = true;
            props.value.wizard.values[`field_2_sindesi_vathron_katastromatos_${i}`] = 1;
        }else if(props.value.wizard.values['sindesi_vathron_katastromatos'] == 2){
            console.log('Value 2');
            // Bearings All. Add Value 2 and Disable Field for Every Pier
            props.disabledFields[`field_2_sindesi_vathron_katastromatos_${i}`] = true
            props.value.wizard.values[`field_2_sindesi_vathron_katastromatos_${i}`] = 2
        }else if(props.value.wizard.values['sindesi_vathron_katastromatos'] == 3){
            console.log('Value 3');
            // Mixed. Enable Field for Every Pier and Dont Add Value.
            props.disabledFields[`field_2_sindesi_vathron_katastromatos_${i}`] = false
        }else{
            console.log('Else');
            //Undefined -> Monolithic All. Add Value 1 and Disable Field for every Pier
            props.disabledFields[`field_2_sindesi_vathron_katastromatos_${i}`] = true
            props.value.wizard.values[`field_2_sindesi_vathron_katastromatos_${i}`] = 1
        }

    }
    function dvalues() {
        if ( typeof props.value.wizard.values.component_threshold !== 'undefined'  && props.value.wizard.values.component_threshold == 1 ) {
            //user-defined
            props.disabledFields['d1p'] = false;
            props.disabledFields['d2p'] = false;
            props.disabledFields['d3p'] = false;
            props.disabledFields['d4p'] = false;
            
            
        }else{
            //default
            props.disabledFields['d1p'] = true;
            props.value.wizard.values['d1p'] = 0.1
            props.disabledFields['d2p'] = true;
            props.value.wizard.values['d2p'] = 0.2
            props.disabledFields['d3p'] = true;
            props.value.wizard.values['d3p'] = 0.3
            props.disabledFields['d4p'] = true;
            props.value.wizard.values['d4p'] = 0.5
        }
    }

    

    function mYφΥ(i){
        if ( typeof props.value.wizard.values['pier_type'] == 'undefined' || props.value.wizard.values['pier_type'] != '2' ) {
            props.disabledFields['polistila_arithmos_stilon'] = true
            props.disabledFields['polistila_apostasis_stilon'] = true
        }
        if ( typeof props.value.wizard.values[`field_2_6_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_6_${i}`] != 0 ) {
            //Proposed Closed Form Relationships
            props.disabledFields[`field_2_15_${i}`] = true;
            props.value.wizard.values[`field_2_15_${i}`] = 0
            props.disabledFields[`field_2_16_${i}`] = true;
            props.value.wizard.values[`field_2_16_${i}`] = 0
            props.disabledFields[`field_2_17_${i}`] = true;
            props.value.wizard.values[`field_2_17_${i}`] = 0
            props.disabledFields[`field_2_18_${i}`] = true;
            props.value.wizard.values[`field_2_18_${i}`] = 0

            props.disabledFields[`field_2_7_${i}`] = false;
            props.disabledFields[`field_2_8_${i}`] = false;
            props.disabledFields[`field_2_9_${i}`] = false;
            props.disabledFields[`field_2_10_${i}`] = false;
            props.disabledFields[`field_2_11_${i}`] = false;
            props.disabledFields[`field_2_12_${i}`] = false;
            props.disabledFields[`field_2_13_${i}`] = false;
            props.disabledFields[`field_2_14_${i}`] = false;
            props.disabledFields[`field_2_18b_${i}`] = false;
            props.disabledFields[`field_2_19_${i}`] = false;
            props.disabledFields[`field_2_20_${i}`] = false;
            props.disabledFields[`field_2_21_${i}`] = false;
            props.disabledFields[`field_2_22_${i}`] = false;
            props.disabledFields[`field_2_23_${i}`] = false;
            props.disabledFields[`field_2_24_${i}`] = false;
            props.disabledFields[`field_2_25_${i}`] = false;
            props.disabledFields[`field_2_26_${i}`] = false;

            // As built parameters
            if ( typeof props.value.wizard.values[`field_2_7_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_7_${i}`] === "1" ){
                //Cylindrical
                
                props.disabledFields[`field_2_9_${i}`] = true;
                props.disabledFields[`field_2_10_${i}`] = true;
                props.value.wizard.values[`field_2_9_${i}`] = 0
                props.value.wizard.values[`field_2_10_${i}`] = 0
            }else if ( typeof props.value.wizard.values[`field_2_7_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_7_${i}`] === "2" ){
                //Hollow Rectangular
                props.disabledFields[`field_2_9_${i}`] = false;
                props.disabledFields[`field_2_10_${i}`] = false;
            }else if ( typeof props.value.wizard.values[`field_2_7_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_7_${i}`] === "3" ){
                //Rectangular
                props.disabledFields[`field_2_9_${i}`] = false;
                props.disabledFields[`field_2_10_${i}`] = true;
                props.value.wizard.values[`field_2_10_${i}`] = 0
            }else if ( typeof props.value.wizard.values[`field_2_7_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_7_${i}`] === "4" ){
                //Wall
                props.disabledFields[`field_2_9_${i}`] = false;
                props.disabledFields[`field_2_10_${i}`] = true;
                props.value.wizard.values[`field_2_10_${i}`] = 0
            }else if ( typeof props.value.wizard.values[`field_2_7_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_7_${i}`] === "5" ){
                //Hollow Cylindrical
                props.disabledFields[`field_2_10_${i}`] = false;
                props.disabledFields[`field_2_9_${i}`] = true;
                props.value.wizard.values[`field_2_9_${i}`] = 0
            }else{
    
            }
            //Retrofitted
            if ( typeof props.value.wizard.values[`field_2_18b_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_18b_${i}`] === "2" ) {
                //FRP
                props.disabledFields[`field_2_19_${i}`] = true;
                props.disabledFields[`field_2_20_${i}`] = true;
                props.disabledFields[`field_2_21_${i}`] = true;
                props.disabledFields[`field_2_22_${i}`] = true;
                props.disabledFields[`field_2_23_${i}`] = true;
                props.value.wizard.values[`field_2_19_${i}`] = 0
                props.value.wizard.values[`field_2_20_${i}`] = 0
                props.value.wizard.values[`field_2_21_${i}`] = 0
                props.value.wizard.values[`field_2_22_${i}`] = 0
                props.value.wizard.values[`field_2_23_${i}`] = 0
    
                props.disabledFields[`field_2_24_${i}`] = false;
                props.disabledFields[`field_2_25_${i}`] = false;
                props.disabledFields[`field_2_26_${i}`] = false;
            }else if ( typeof props.value.wizard.values[`field_2_18b_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_18b_${i}`] === "1" ){
                //RC Jacket
                props.disabledFields[`field_2_19_${i}`] = false;
                props.disabledFields[`field_2_20_${i}`] = false;
                props.disabledFields[`field_2_21_${i}`] = false;
                props.disabledFields[`field_2_22_${i}`] = false;
                props.disabledFields[`field_2_23_${i}`] = false;
    
                props.disabledFields[`field_2_24_${i}`] = true;
                props.disabledFields[`field_2_25_${i}`] = true;
                props.disabledFields[`field_2_26_${i}`] = true;
                props.value.wizard.values[`field_2_24_${i}`] = 0
                props.value.wizard.values[`field_2_25_${i}`] = 0
                props.value.wizard.values[`field_2_26_${i}`] = 0
            }else if ( typeof props.value.wizard.values[`field_2_18b_${i}`] == 'undefined'  || props.value.wizard.values[`field_2_18b_${i}`] === "0" ){
                //None
                props.disabledFields[`field_2_19_${i}`] = true;
                props.disabledFields[`field_2_20_${i}`] = true;
                props.disabledFields[`field_2_21_${i}`] = true;
                props.disabledFields[`field_2_22_${i}`] = true;
                props.disabledFields[`field_2_23_${i}`] = true;
                props.value.wizard.values[`field_2_19_${i}`] = 0
                props.value.wizard.values[`field_2_20_${i}`] = 0
                props.value.wizard.values[`field_2_21_${i}`] = 0
                props.value.wizard.values[`field_2_22_${i}`] = 0
                props.value.wizard.values[`field_2_23_${i}`] = 0
                props.disabledFields[`field_2_24_${i}`] = true;
                props.disabledFields[`field_2_25_${i}`] = true;
                props.disabledFields[`field_2_26_${i}`] = true;
                props.value.wizard.values[`field_2_24_${i}`] = 0
                props.value.wizard.values[`field_2_25_${i}`] = 0
                props.value.wizard.values[`field_2_26_${i}`] = 0
            }else{

            }

        } else {
            //User Defined
            props.disabledFields[`field_2_15_${i}`] = false;
            props.disabledFields[`field_2_16_${i}`] = false;
            props.disabledFields[`field_2_17_${i}`] = false;
            props.disabledFields[`field_2_18_${i}`] = false;

            props.disabledFields[`field_2_7_${i}`] = true;
            props.disabledFields[`field_2_8_${i}`] = true;
            props.disabledFields[`field_2_9_${i}`] = true;
            props.disabledFields[`field_2_10_${i}`] = true;
            props.disabledFields[`field_2_11_${i}`] = true;
            props.disabledFields[`field_2_12_${i}`] = true;
            props.disabledFields[`field_2_13_${i}`] = true;
            props.disabledFields[`field_2_14_${i}`] = true;
            props.disabledFields[`field_2_18b_${i}`] = true;
            props.disabledFields[`field_2_19_${i}`] = true;
            props.disabledFields[`field_2_20_${i}`] = true;
            props.disabledFields[`field_2_21_${i}`] = true;
            props.disabledFields[`field_2_22_${i}`] = true;
            props.disabledFields[`field_2_23_${i}`] = true;
            props.disabledFields[`field_2_24_${i}`] = true;
            props.disabledFields[`field_2_25_${i}`] = true;
            props.disabledFields[`field_2_26_${i}`] = true;
        }

        
    }
    

    // function changeDisabledFields(i) {

    //     for (let j = 19; j <= 26; j++ ) {
    //         props.disabledFields[`field_2_${j}_${i}`] = true
    //         // props.value.wizard.values[`field_2_${j}_${i}`] = 0
    //     }

    //     if ( typeof props.value.wizard.values['pier_type'] == 'undefined' || props.value.wizard.values['pier_type'] != '2' ) {
    //         props.disabledFields['polistila_arithmos_stilon'] = true
    //         props.disabledFields['polistila_apostasis_stilon'] = true
    //     }


    //     if ( typeof props.value.wizard.values[`field_2_6_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_6_${i}`] != 0 ) {
    //         props.disabledFields[`field_2_15_${i}`] = true;
    //         props.disabledFields[`field_2_16_${i}`] = true;
    //         props.disabledFields[`field_2_17_${i}`] = true;
    //         props.disabledFields[`field_2_18_${i}`] = true;
    //         props.value.wizard.values[`field_2_15_${i}`] = 0
    //         props.value.wizard.values[`field_2_16_${i}`] = 0
    //         props.value.wizard.values[`field_2_17_${i}`] = 0
    //         props.value.wizard.values[`field_2_18_${i}`] = 0


    //         props.disabledFields[`field_2_7_${i}`] = false;
    //         props.disabledFields[`field_2_8_${i}`] = false;
    //         props.disabledFields[`field_2_9_${i}`] = false;
    //         props.disabledFields[`field_2_10_${i}`] = false;
    //         props.disabledFields[`field_2_11_${i}`] = false;
    //         props.disabledFields[`field_2_12_${i}`] = false;
    //         props.disabledFields[`field_2_13_${i}`] = false;
    //         props.disabledFields[`field_2_14_${i}`] = false;

    //     } else {
    //         props.disabledFields[`field_2_15_${i}`] = false;
    //         props.disabledFields[`field_2_16_${i}`] = false;
    //         props.disabledFields[`field_2_17_${i}`] = false;
    //         props.disabledFields[`field_2_18_${i}`] = false;

    //         props.disabledFields[`field_2_7_${i}`] = true;
    //         props.disabledFields[`field_2_8_${i}`] = true;
    //         props.disabledFields[`field_2_9_${i}`] = true;
    //         props.disabledFields[`field_2_10_${i}`] = true;
    //         props.disabledFields[`field_2_11_${i}`] = true;
    //         props.disabledFields[`field_2_12_${i}`] = true;
    //         props.disabledFields[`field_2_13_${i}`] = true;
    //         props.disabledFields[`field_2_14_${i}`] = true;
    //     }
        

        
        
        
        


        // if ( typeof props.value.wizard.values[`field_2_7_${i}`] !== 'undefined' && props.value.wizard.values[`field_2_7_${i}`] !== "6" && props.value.wizard.values[`field_2_7_${i}`] !== "7") {
        //     for (let j = 19; j <= 26; j++ ) {
        //         props.disabledFields[`field_2_${j}_${i}`] = true
        //         props.value.wizard.values[`field_2_${j}_${i}`] = 0
        //     }
         
        // } else if ( typeof props.value.wizard.values[`field_2_7_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_7_${i}`] === "6" ) {
        //     for (let k = 19; k <= 23; k++ ) {
        //         delete props.disabledFields[`field_2_${k}_${i}`]
        //     }
        //     for (let m = 24; m <= 26; m++ ) {
        //         props.disabledFields[`field_2_${m}_${i}`] = true
        //         props.value.wizard.values[`field_2_${m}_${i}`] = 0
        //     }
        // }  else if ( typeof props.value.wizard.values[`field_2_7_${i}`] !== 'undefined'  && props.value.wizard.values[`field_2_7_${i}`] === "7" ) {
        //     for (let t = 19; t <= 23; t++ ) {
        //         props.disabledFields[`field_2_${t}_${i}`] = true
        //         props.value.wizard.values[`field_2_${t}_${i}`] = 0
        //     }
        //     for (let p = 24; p <= 26; p++ ) {
        //         delete props.disabledFields[`field_2_${p}_${i}`]
        //     }
        // }


    // }
    [...Array(arithmos_anoigmaton)].map((_, i) => {mYφΥ(i)})

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex-col-new">
            <div className="d_values">
                <Field
                    name="pier_type"
                    component={renderSelect}
                    label="Pier type"
                    options={{"1":"Single Column Piers", "2":"Multi-column Piers"}}
                    disabled={props.disabledFields.pier_type}
                    onChange={pierType}
                    infoText="Select if the bridge has single or multi-column piers. The bridge can either have single or multi-column piers and not both pier types."
                /> 
                <Field
                    name="polistila_arithmos_stilon"     
                    type="number"
                    component={renderField}
                    label="Multi-column Piers : Number of piers"
                    normalize={normAll([normRules.integerOnly])}
                    disabled={props.disabledFields.polistila_arithmos_stilon}
                    infoText="Select the number of piers of each multi-column pier"
                />
                <Field
                    name="polistila_apostasis_stilon"     
                    type="text"
                    component={renderField}
                    label="Multi-column Piers : Pier distance"
                    customClass="long-input"
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.polistila_apostasis_stilon}
                    infoText="For the case of multi-column piers complete the distance between the piers of the multicolumn pier (e.g. for a multi-column pier with 4 piers having a distance 1.5m between them (total distance in the transverse direction equal to 4.5m)  write 1.5;1.5;1.5."
                />
            </div>
            <div className="d_values">
                <h4>Pier Threshold Values</h4>
                <Field
                    name="d1p"     
                    type="text"
                    component={renderField}
                    onChange={dvalues()}
                    label="d1P (m)"
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d1p}
                    infoText="Pier Threshold Values (LS1) - Must be positive value, up to 3 decimals"
                />
                <Field
                    name="d2p"     
                    type="text"
                    component={renderField}
                    onChange={dvalues()}
                    label="d2P (m)"
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d2p}
                    infoText="Pier Threshold Values (LS2) - Must be positive value, up to 3 decimals"
                />
                <Field
                    name="d3p"     
                    type="text"
                    component={renderField}
                    onChange={dvalues()}
                    label="d3P (m)"
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d3p}
                    infoText="Pier Threshold Values (LS3) - Must be positive value, up to 3 decimals"
                />
                <Field
                    name="d4p"     
                    type="text"
                    component={renderField}
                    onChange={dvalues()}
                    label="d4P (m)"
                    normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                    disabled={props.disabledFields.d4p}
                    infoText="Pier Threshold Values (LS4) - Must be positive value, up to 3 decimals"
                />
            </div>
            </div>
            <div className="images-col imagestep2">
            <a href={Image2} target="_blank"><img src={Image2} alt="The Bridge Database" /></a>
            </div>
            {[...Array(arithmos_anoigmaton)].map((_, i) => {
   
                return <div key={i} className={i <= arithmos_anoigmaton-1 && i != 0 ? "wizard-step2-group hide-this" : "wizard-step2-group"} id={`step2_vathro_${i}`}>
                    <div className="group-header"><h3>Pier Number: #{i + 1}</h3></div>
                    <div className="flex-col">
                    <div className="group-fields">
                        <div className="flex-col-1">
                        <Field
                            name={`field_2_sindesi_vathron_katastromatos_${i}`}
                            component={renderSelect}
                            options={{"1":"Monolithic", "2":"Bearings"}}
                            label="Pier to Deck Connection"
                            onChange={connectionType(i)}
                            disabled={props.disabledFields[`field_2_sindesi_vathron_katastromatos_${i}`]}
                            infoText="Select the pier to deck connection at every pier location."
                        />
                        </div>
                    </div>
                    <div className="group-fields">
                        <h4>Geometry</h4>
                        <div className="flex-col-1">
                        <Field
                            name={`field_2_1_${i}`}
                            type="text"
                            component={renderField}
                            label={`${i+1}.1 Pier Section Area (m<sup>2</sup>)`}
                            normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}   
                        />
                        <Field
                            name={`field_2_2_${i}`}
                            type="text"
                            component={renderField}
                            label={`${i+1}.2  Moment of Inertia (m<sup>4</sup>) <span>[Strong Direction]</span>`}
                            normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                            infoText="Moment of inertia at the strong direction"
                        />
                        <Field
                            name={`field_2_3_${i}`}
                            type="text"
                            component={renderField}
                            label={`${i+1}.3  Moment of Inertia (m<sup>4</sup>) <span>[Weak Direction]</span>`}
                            normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                            infoText="Moment of inertia at the weak direction"
                        />
                        </div>
                        <div className="flex-col-2">
                        <Field
                            name={`field_2_4_${i}`}
                            type="text"
                            component={renderField}
                            label={`${i+1}.4  Torsional moment of Inertia (m<sup>4</sup>) <span>[<sub>*</sub>0,10]</span>`}
                            normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        />
                        <Field
                            name={`field_2_5_${i}`}
                            type="text"
                            component={renderField}
                            label={`${i+1}.5  Modulus of Elasticity (kN/m<sup>2</sup>)`}
                            normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        />
                        </div>
                    </div>
                    <div className="group-fields">
                        <h4>Μ<sub>y</sub>-φ<sub>y</sub> relationships (to account for reduced stiffness)</h4>
                        <div className="flex-col-1">
                            <Field
                                name={`field_2_6_${i}`}
                                component={renderSelect}
                                label={`${i+1}.6 M<sub>y</sub> - Φ<sub>y</sub>`}
                                options={{"0":"User defined", "1":"Closed form relationships"}}
                                onChange={mYφΥ(i)}
                                infoText="Select if the M<sub>y</sub> - φ<sub>y</sub> relationships (necessary for the estimation of the reduced stiffness EI<sub>eff</sub>= M<sub>y</sub>/φ<sub>y</sub>) will be a) automatically calculated based on closed form relationships or b) user defined."
                            />
                        </div>
                    </div>
                    <div className="group-fields">
                    <h4>Closed form relationships M<sub>y</sub> - φ<sub>y</sub> values (as built parameters)</h4>
                    <div className="flex-col-1">
                    <Field
                        name={`field_2_7_${i}`}
                        component={renderSelect}
                        label={`${i+1}.7 Pier Section`}
                        options={{"1":"Cylindrical", "2":"Hollow Rectangular", "3":"Rectangular", "4":"Wall", "5":"Hollow Cylindrical"}}
                        infoText="Select the pier section"
                        onChange={mYφΥ(i)}
                        disabled={props.disabledFields[`field_2_7_${i}`]}
                    />
                    <Field
                        name={`field_2_8_${i}`}
                        type="text"
                        component={renderField}
                        label={props.value.wizard.values[`field_2_7_${i}`] === "1" || props.value.wizard.values[`field_2_7_${i}`] === "5" ? `${i+1}.8 D(m)` : `${i+1}.8 H(m)`}   
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        infoText={props.value.wizard.values[`field_2_7_${i}`] === "1" || props.value.wizard.values[`field_2_7_${i}`] === "5" ? 'D: Diameter of cylindrical piers, External diameter of hollow cylindrical piers' : 'H: Strong direction of rectangular, wall, hollow rectangular piers'} 
                        onChange={mYφΥ(i)}
                        disabled={props.disabledFields[`field_2_8_${i}`]}
                    />
                    <Field
                        name={`field_2_9_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.9 B(m)`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        infoText="B: Weak direction of rectangular, wall, hollow rectangular piers."
                        onChange={mYφΥ(i)}
                        disabled={props.disabledFields[`field_2_9_${i}`]}
                    />
                    <Field
                        name={`field_2_10_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.10 t(m)`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        infoText="t : Thickness of hollow rectangular or hollow circular piers"
                        onChange={mYφΥ(i)}
                        disabled={props.disabledFields[`field_2_10_${i}`]}
                    />
                    </div>
                    <div className="flex-col-2">
                    <Field
                        name={`field_2_11_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.11 f<sub>c</sub> (MPa)`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        infoText="Concrete compressive strength (e.g. for C 20/25, set the value 25)"
                        onChange={mYφΥ(i)}
                        disabled={props.disabledFields[`field_2_11_${i}`]}
                    />
                    <Field
                        name={`field_2_12_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.12 f<sub>y</sub> (MPa)`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        infoText="Steel tensile strength (e.g. for S400, set the value 400)"
                        onChange={mYφΥ(i)}
                        disabled={props.disabledFields[`field_2_12_${i}`]}
                    />
                    <Field
                        name={`field_2_13_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.13 ρ<sub>l</sub>`}  
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        infoText="Longitudinal reinforcement ratio (e.g. for 1% set the value 0.01)"
                        onChange={mYφΥ(i)}
                        disabled={props.disabledFields[`field_2_13_${i}`]}
                    />
                    <Field
                        name={`field_2_14_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.14 ρ<sub>w</sub>`}    
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        infoText="Transverse reinforcement ratio (e.g. for 1% set the value 0.01)"
                        onChange={mYφΥ(i)}
                        disabled={props.disabledFields[`field_2_14_${i}`]}
                    />
                    </div>
                    </div>
                    <div className="group-fields">
                    <h4>Closed form relationships M<sub>y</sub>-φ<sub>y</sub> values (retrofitted piers)</h4>
                    <div className="flex-col-1">
                    <Field
                        name={`field_2_18b_${i}`}
                        component={renderSelect}
                        label="Retrofit Measure"
                        options={{"0":"None", "1":"RC Jacket", "2":"FRP jacket"}}
                        infoText="Applicable only for circular and hollow circular piers"
                        disabled={props.disabledFields[`field_2_18b_${i}`]}
                        onChange={mYφΥ(i)}
                    />
                    <Field
                        name={`field_2_19_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.15 D<sub>RCj</sub> (m)`}     
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_19_${i}`]}
                        infoText="Total Diameter of the RC jacketed section"
                    />
                    <Field
                        name={`field_2_20_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.16 ρ<sub>l,RCj</sub>`}     
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_20_${i}`]}
                        infoText="Longitudinal reinforcement ratio of the jacket (e.g. for 1% set the value 0.01)"
                    />
                    <Field
                        name={`field_2_21_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.17 ρ<sub>w,RCj</sub>`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_21_${i}`]}
                        infoText="Transverse reinforcement ratio of the jacket (e.g. for 1% set the value 0.01)"
                    />
                    <Field
                        name={`field_2_22_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.18 F<sub>c,RCj</sub> (MPa)`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_22_${i}`]}
                        infoText="Concrete compressive strength of the jacket (e.g. for C 20/25, set the value 25)"
                    />
                    </div>
                    <div className="flex-col-2">
                    <Field
                        name={`field_2_23_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.19 F<sub>y,RCj</sub> (MPa)`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_23_${i}`]}
                        infoText="Steel tensile strength of the jacket (e.g. for S400, set the value 400)"
                    />
                    <Field
                        name={`field_2_24_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.20 D<sub>FRPj</sub> (m)`}     
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_24_${i}`]}
                        infoText="Total Diameter of the FRP jacketed section"
                    />
                    <Field
                        name={`field_2_25_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.21 E<sub>j,FRP</sub> (MPa)`}     
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_25_${i}`]}
                        infoText="Modulus of Elasticity of the FRP "
                    />
                    <Field
                        name={`field_2_26_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.22 f<sub>j,FRP</sub> (MPa)`}     
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_26_${i}`]}
                        infoText="Tensile strength of the FRP"
                    />
                    </div>
                    </div>
                    <div className="group-fields">
                    <h4>User Defined M<sub>y</sub> - Φ<sub>y</sub> values</h4>
                    <div className="flex-col-1">
                    <Field
                        name={`field_2_15_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.23 M<sub>y</sub>(y) (kNm)`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_15_${i}`]}
                        infoText="Yielding moment (strong direction)"
                    />
                    <Field
                        name={`field_2_16_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.24 φ<sub>y</sub>(y) (rad)`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_16_${i}`]}
                        infoText="Yielding curvature (strong direction)"
                    />
                    </div>
                    <div className="flex-col-2">
                    <Field
                        name={`field_2_17_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.25 M<sub>y</sub>(z) (kNm)`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_17_${i}`]}
                        infoText="Yielding moment (weak direction)"
                    />
                    <Field
                        name={`field_2_18_${i}`}
                        type="text"
                        component={renderField}
                        label={`${i+1}.26 φ<sub>y</sub>(z) (rad)`}
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields[`field_2_18_${i}`]}
                        infoText="Yielding curvature (strong direction)"
                    />
                    </div>
                    </div>
                    </div>
                    
                    { i < arithmos_anoigmaton - 1  && <div className="flex-col buttons-step"><button id={`button_step2_vathro_${i}`} type="button" onClick={nextVathro}>
                        Proceed to Pier #{i + 2} from {arithmos_anoigmaton} in total<FontAwesomeIcon icon={faChevronRight} />
                        </button></div>}
                    
                </div>
            })
        }

        <div className="buttons-main">
            <button type="button" className="previous" onClick={previousPage}>
            <FontAwesomeIcon icon={faChevronLeft} /> Back to General Properties 
            </button>
            <button type="submit" className="next" disabled={props.invalid}>
            Proceed to Bearing Properties <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
        </form>
    )
}

function mapStateToProps(state) {
    return { value: state.form, disabledFields: {} };
}


WizardFormSecondPage = connect(mapStateToProps)(WizardFormSecondPage)

export default reduxForm({
  form: 'wizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  initialValues: {}
})(WizardFormSecondPage)