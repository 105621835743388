import React from 'react'
import { Field, reduxForm, formValueSelector, getFormValues } from 'redux-form'
import validate from './validate'
import normalization from './normalization'
import renderField from './renderField'
import renderSelect from './renderSelect'
import renderTextarea from './renderTextarea'
import { Line } from 'react-chartjs-2'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Image1 from '../../images/osees_image1.jpg'
import Image2 from '../../images/osees_image2.jpg'

import { formValues } from 'redux-form' // ES6

let WizardFormFirstPage = props => {
    const { handleSubmit } = props

    const normRules = normalization().rules
    const normAll = normalization().normalizeAll
    let chartData = props.value.wizard.values.fasma_ec8.split(/\n/).map(x => x.split(';'))
    
    changeDisabledFields()
    
    const data = {
      labels: chartData.map(x => x[0]),
      datasets: [
        {
          label: "Spectrum x & y",
          data: chartData.map(x => x[1]),
          fill: false,
          backgroundColor: 'rgb(50, 70, 211)',
          borderColor: 'rgba(50, 70, 211, 0.2)',
        },
      ],
    }
    
    const options = {
      scales: {
        yAxes: [
          {
            ticks: {
                beginAtZero: true,
            },
            scaleLabel: {
                display: true,
                labelString: 'Sa(g)'
            },
          }
        ],
        xAxes: [
            {
              ticks: {
                  beginAtZero: true,
              },
              scaleLabel: {
                  display: true,
                  labelString: 'T(sec)'
              },
            }
          ],
      },
    }
    function dvalues() {
        if ( typeof props.value.wizard.values.component_threshold !== 'undefined'  && props.value.wizard.values.component_threshold == 1 ) {
            //user-defined
            console.log('user defined')
            
            
            
        }else{
            console.log('default')
            //default, set initial values
            props.value.wizard.values['d1p'] = 0.1
            props.value.wizard.values['d2p'] = 0.2
            props.value.wizard.values['d3p'] = 0.3
            props.value.wizard.values['d4p'] = 0.5
            props.value.wizard.values['d1b'] = 0.1
            props.value.wizard.values['d2b'] = 0.2
            props.value.wizard.values['d3b'] = 0.3
            props.value.wizard.values['d4b'] = 0.5
            props.value.wizard.values['d1a'] = 0.1
            props.value.wizard.values['d2a'] = 0.2
            props.value.wizard.values['d3a'] = 0.3
            props.value.wizard.values['d4a'] = 0.5
        }
    }

    function updateChart() {
        chartData = props.value.wizard.values.fasma_ec8.split(/\n/).map(x => x.split(';'))
    }
    

    function changeDisabledFields() {
        if ( !props.value.wizard.values.arithmos_anoigmaton ) {
            props.disabledFields['mhkh_anoigmaton'] = true;
            props.disabledFields['diakritopoihsh_anoigmaton'] = true;
            props.disabledFields['ypsoi_vathron'] = true;
        }
        if ( !props.value.wizard.values.polistila_arithmos_stilon ) {
            props.disabledFields['polistila_apostasis_stilon'] = true;
        }
        if ( typeof props.value.wizard.values.arithmos_anoigmaton !== 'undefined'  && props.value.wizard.values.arithmos_anoigmaton == 1 ) {
            props.disabledFields['ypsoi_vathron'] = true;
            props.value.wizard.values['ypsoi_vathron'] = 0
        } else if ( typeof props.value.wizard.values.arithmos_anoigmaton !== 'undefined'  && props.value.wizard.values.arithmos_anoigmaton > 1){
            delete props.disabledFields['ypsoi_vathron'];
            //delete props.value.wizard.values['ypsoi_vathron'];
        }

        //disable all fields at start
        // const fields = ["arithmos_anoigmaton","mhkh_anoigmaton","diakritopoihsh_anoigmaton","omoiomorfo_fortio","ypsoi_vathron","geometria_katastromatos","arithmos_idiomorfon","dieuthinsi_fortisis","aposvesi","sindesi_vathron_katastromatos","polistila_arithmos_stilon","polistila_apostasis_stilon","stathmes_vlavis","fasma_ec8"]
        // fields.map(function(x) {
        //     props.disabledFields[x] = true;
        // });
        // //always enable first field
        // fields.forEach(( v, ind) => {
        //     // console.log(ind,v,fields[ind + 1])
        //     if ( ind === 0) {
        //         props.disabledFields[v] = false;
        //     }
        //     if ( ind < fields.length ) {
        //        // console.log(ind, v)
        //         if ( v in props.value.wizard.values ) {
 
        //             props.disabledFields[fields[ind + 1]] = false
        //         }
        //     }
        // })
    }

  

    return (
        <form onSubmit={handleSubmit}>
            <div className="flex-col">
                <div className="flex-col-1">
                    <Field
                        name="arithmos_anoigmaton"
                        type="number"
                        component={renderField}
                        label="1. Number of spans"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.integerOnly])} 
                        disabled={props.disabledFields.arithmos_anoigmaton}
                        onChange={changeDisabledFields()}
                        infoText="Number of bridge spans"
                    />
                    <Field
                        name="mhkh_anoigmaton"
                        type="text"
                        component={renderField}
                        label="2. Span Lengths (m)"
                        customClass="long-input"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields.mhkh_anoigmaton}
                        onChange={changeDisabledFields()}
                        infoText="Complete the length of every span from left to right in (m) separated with semicolon. e.g. for a bridge with 3 spans having lengths 25m, 45m, 25m complete with the following : 25;45;25"
                    />
                    <Field
                        name="diakritopoihsh_anoigmaton"
                        type="text"
                        component={renderField}
                        label="3. Meshsize for every span"
                        customClass="long-input"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields.diakritopoihsh_anoigmaton}
                        onChange={changeDisabledFields()}
                        infoText="Select meshsize for every span. e.g. for a bridge with 3 spans if the meshsize for every span is 10 complete with the following : 10;10;10"
                    />
                    <Field
                        name="omoiomorfo_fortio"
                        type="text"
                        component={renderField}
                        label="4. Distributed Span Load (G+0.2Q) (kN/m<sup>2</sup>)"
                        customClass="long-input"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields.omoiomorfo_fortio}
                        onChange={changeDisabledFields()}
                        infoText="Assign the distributed load at every span (combination G+0.2Q). e.g. for a bridge with 3 spans if the distributed load for the G+0.2Q combination is 30.3kN/m2 complete with the following : 30.3;30.3;30.3"
                    />
                    <Field
                        name="ypsoi_vathron"
                        type="text"
                        component={renderField}
                        label="5. Pier Height"
                        customClass="long-input"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields.ypsoi_vathron}
                        infoText="For all piers from left to right complete the pier heights in (m). e.g. for a bridge with 3 spans and 2 piers with heights 20m(left pier) and 25m(right pier) complete with the following : 20;25"
                    />
                    <Field
                        name="geometria_katastromatos"
                        type="text"
                        component={renderField}
                        label="6. Deck Geometry"
                        customClass="long-input"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields.geometria_katastromatos}
                        infoText="Complete the deck geometry first at span and then at support location. The sequence should be the following:<br />A(m<sup>2</sup>)<sub>,span</sub> ; J(m<sup>4</sup>)<sub>,span</sub>x0,1 ; Iz(m<sup>4</sup>)<sub>,span</sub> ; Iy(m<sup>4</sup>)<sub>,span</sub> ; E(kN/m<sup>2</sup>) ; A(m<sup>2</sup>)<sub>,supp</sub> ; J(m<sup>4</sup>)<sub>,supp</sub>x0,1 ; Iz(m<sup>4</sup>)<sub>,supp</sub> ; Iy(m<sup>4</sup>)<sub>,supp</sub><br />e.g.<br />6.32 ; 0.15 ; 2.82 ; 80.51 ; 35000000 ; 9.08 ; 1.5 ; 3.73 ; 111.76"
                        
                    />
                </div>
                <div className="flex-col-2">
                    <Field
                        name="arithmos_idiomorfon"
                        disabled={true}      
                        type="text"
                        component={renderField}
                        label="7. Number of eigenmodes"
                        disabled={props.disabledFields.arithmos_idiomorfon}
                        infoText="Complete the number of eigenmodes to be considered."
                    />
                    <Field
                        name="dieuthinsi_fortisis"
                        component={renderSelect}
                        label="8. Direction of loading"
                        options={{"1":"x", "2":"y"}}
                        disabled={props.disabledFields.dieuthinsi_fortisis}
                        infoText="Select the direction of loading, x-longitudinal or y-transverse based on the figure."
                    /> 
                    <Field
                        name="aposvesi"     
                        type="number"
                        step="0.01"
                        component={renderField}
                        label="9. Damping"
                        disabled={props.disabledFields.aposvesi}
                        infoText="Select the damping value e.g. for ζ=5% write 0.05."
                    />
                    {<Field
                        name="sindesi_vathron_katastromatos"
                        component={renderSelect}
                        label="10. Pier to Deck Connection"
                        options={{"1":"Monolithic", "2":"Bearings", "3":"Monolithic & Bearings"}}
                        disabled={props.disabledFields.sindesi_vathron_katastromatos}
                    /> 
                    /*
                    <Field
                        name="polistila_arithmos_stilon"     
                        type="number"
                        component={renderField}
                        label="Πολύστυλα: Αριθμός στυλών"
                        normalize={normAll([normRules.integerOnly])}
                        disabled={props.disabledFields.polistila_arithmos_stilon}
                    />
                    <Field
                        name="polistila_apostasis_stilon"     
                        type="text"
                        component={renderField}
                        label="Πολύστυλα: Αποστάσεις στυλών"
                        customClass="long-input"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields.polistila_apostasis_stilon}
                    /> */}
                    
                    <Field
                        name="stathmes_vlavis"     
                        type="text"
                        component={renderField}
                        label="11. b<sub>tot</sub> (LS1, LS2, LS3, LS4)"
                        customClass="long-input"
                        normalize={normAll([normRules.noSpaces,normRules.noCommas, normRules.noDuplicatedSymbols])}
                        disabled={props.disabledFields.stathmes_vlavis}
                        infoText="Complete the dispersion value for every limit state (LS1, LS2, LS3, LS4) as described in:  Stefanidou S, Kappos A (2017) Methodology for the development of bridge-specific fragility curves Earthq Eng &amp; Struct Dyn 46:73-93, DOI: 10.1002/eqe.2774 , separated with semicolon e.g. 0.55;0.65;0.75;0.85."
                    />
                    <Field
                        name="component_threshold"
                        component={renderSelect}
                        label="12. Component Threshold Values"
                        options={{"0":"Default", "1":"User Defined"}}
                        onChange={dvalues()}
                        disabled={props.disabledFields.component_threshold}
                        infoText="Select default (according to Stefanidou  Kappos, EESD, 2017) values for component thresholds, or used defined (to be competed in the relevant forms for every component)"
                    /> 
                </div>
                <div className="flex-col-1">
                    <Field
                        name="fasma_ec8"     
                        component={renderTextarea}
                        rows="25"
                        cols="30"
                        label="Spectrum x &amp; y"
                        onChange={ updateChart() }
                        disabled={props.disabledFields.fasma_ec8}
                        infoText="Copy-paste your spectrum values in Sa-T terms in the box below"

                    />
                </div>
                <div className="flex-col-2">
                    <div className="wizard-chart"><Line data={data} options={options} width={300} height={200} /></div>
                </div>
        </div>
        <div className="images-col">
            <a href={Image1} target="_blank"><img src={Image1} alt="The Bridge Database Opensees" /></a>
            <a href={Image2} target="_blank"><img src={Image2} alt="The Bridge Database Opensees" /></a>
        </div>
        
        <div className="buttons-main">
            <button type="submit" className="next" disabled={props.invalid}>
            Proceed to Pier Properties <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
        </form>
    )
}

function mapStateToProps(state) {
    return { value: state.form, disabledFields: {} };
}

WizardFormFirstPage = connect(mapStateToProps)(WizardFormFirstPage)

WizardFormFirstPage = reduxForm({
  form: 'wizard', // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate,
  initialValues: {
      "arithmos_anoigmaton": 3,
      "mhkh_anoigmaton": "36;37;36",
      "diakritopoihsh_anoigmaton": "10;10;10",
      "omoiomorfo_fortio" : "30;30;30",
      "ypsoi_vathron" : "9.40;14.32",
      "geometria_katastromatos": "6.32;0.15;2.82;80.51;35000000;9.08;1.5;3.73;111.76",
      "arithmos_idiomorfon": 10,
      "dieuthinsi_fortisis": "1",
      "aposvesi": 0.05,
      "polistila_arithmos_stilon": 1,
      "polistila_apostasis_stilon": "1",
      "stathmes_vlavis": "0.9;0.9;0.9;0.9",
      "sindesi_vathron_katastromatos": "1",
      "fasma_ec8": `0;0.288
0.05;0.432
0.1;0.576
0.15;0.72
0.2;0.72
0.25;0.72
0.3;0.72
0.35;0.72
0.4;0.72
0.45;0.72
0.5;0.72
0.55;0.654545455
0.6;0.6
0.65;0.553846154
0.7;0.514285714
0.75;0.48
0.8;0.45
0.85;0.423529412
0.9;0.4
0.95;0.378947368
1;0.36
1.05;0.342857143
1.1;0.327272727
1.15;0.313043478
1.2;0.3
1.25;0.288
1.3;0.276923077
1.35;0.266666667
1.4;0.257142857
1.45;0.248275862
1.5;0.24
1.55;0.232258065
1.6;0.225
1.65;0.218181818
1.7;0.211764706
1.75;0.205714286
1.8;0.2
1.85;0.194594595
1.9;0.189473684
1.95;0.184615385
2;0.18
2.05;0.175609756
2.1;0.171428571
2.15;0.16744186
2.2;0.163636364
2.25;0.16
2.3;0.156521739
2.35;0.153191489
2.4;0.15
2.45;0.146938776
2.5;0.144
2.55;0.138408304
2.6;0.133136095
2.65;0.128159487
2.7;0.12345679
2.75;0.119008264
2.8;0.114795918
2.85;0.110803324
2.9;0.107015458
2.95;0.103418558
3;0.1
3.05;0.096748186
3.1;0.093652445
3.15;0.090702948
3.2;0.087890625
3.25;0.085207101
3.3;0.082644628
3.35;0.080196035
3.4;0.077854671
3.45;0.075614367
3.5;0.073469388
3.55;0.071414402
3.6;0.069444444
3.65;0.067554888
3.7;0.065741417
3.75;0.064
3.8;0.06232687
3.85;0.060718502
3.9;0.059171598
3.95;0.057683064
4;0.05625`,
'field_2_10_0': '0.5',
 'field_2_10_1': '0.5',
 'field_2_10_2': '0.5',
 'field_2_10_3': '0.5',
 'field_2_10_4': '0.5',
 'field_2_10_5': '0.5',
 'field_2_10_6': '0.5',
 'field_2_10_7': '0.5',
 'field_2_11_0': '38',
 'field_2_11_1': '38',
 'field_2_11_2': '38',
 'field_2_11_3': '38',
 'field_2_11_4': '38',
 'field_2_11_5': '38',
 'field_2_11_6': '38',
 'field_2_11_7': '38',
 'field_2_12_0': '550',
 'field_2_12_1': '550',
 'field_2_12_2': '550',
 'field_2_12_3': '550',
 'field_2_12_4': '550',
 'field_2_12_5': '550',
 'field_2_12_6': '550',
 'field_2_12_7': '550',
 'field_2_13_0': '0.011',
 'field_2_13_1': '0.011',
 'field_2_13_2': '0.011',
 'field_2_13_3': '0.011',
 'field_2_13_4': '0.011',
 'field_2_13_5': '0.011',
 'field_2_13_6': '0.011',
 'field_2_13_7': '0.011',
 'field_2_14_0': '0.0035',
 'field_2_14_1': '0.0035',
 'field_2_14_2': '0.0035',
 'field_2_14_3': '0.0035',
 'field_2_14_4': '0.0035',
 'field_2_14_5': '0.0035',
 'field_2_14_6': '0.0035',
 'field_2_14_7': '0.0035',
 'field_2_15_0': '0',
 'field_2_15_1': '0',
 'field_2_15_2': '0',
 'field_2_15_3': '0',
 'field_2_15_4': '0',
 'field_2_15_5': '0',
 'field_2_15_6': '0',
 'field_2_15_7': '0',
 'field_2_16_0': '0',
 'field_2_16_1': '0',
 'field_2_16_2': '0',
 'field_2_16_3': '0',
 'field_2_16_4': '0',
 'field_2_16_5': '0',
 'field_2_16_6': '0',
 'field_2_16_7': '0',
 'field_2_17_0': '0',
 'field_2_17_1': '0',
 'field_2_17_2': '0',
 'field_2_17_3': '0',
 'field_2_17_4': '0',
 'field_2_17_5': '0',
 'field_2_17_6': '0',
 'field_2_17_7': '0',
 'field_2_18_0': '0',
 'field_2_18_1': '0',
 'field_2_18_2': '0',
 'field_2_18_3': '0',
 'field_2_18_4': '0',
 'field_2_18_5': '0',
 'field_2_18_6': '0',
 'field_2_18_7': '0',
 'field_2_19_0': '0',
 'field_2_19_1': '0',
 'field_2_19_2': '0',
 'field_2_19_3': '0',
 'field_2_19_4': '0',
 'field_2_19_5': '0',
 'field_2_19_6': '0',
 'field_2_19_7': '0',
 'field_2_1_0': '4.24',
 'field_2_1_1': '4.24',
 'field_2_1_2': '4.24',
 'field_2_1_3': '4.24',
 'field_2_1_4': '4.24',
 'field_2_1_5': '4.24',
 'field_2_1_6': '4.24',
 'field_2_1_7': '4.24',
 'field_2_20_0': '0',
 'field_2_20_1': '0',
 'field_2_20_2': '0',
 'field_2_20_3': '0',
 'field_2_20_4': '0',
 'field_2_20_5': '0',
 'field_2_20_6': '0',
 'field_2_20_7': '0',
 'field_2_21_0': '0',
 'field_2_21_1': '0',
 'field_2_21_2': '0',
 'field_2_21_3': '0',
 'field_2_21_4': '0',
 'field_2_21_5': '0',
 'field_2_21_6': '0',
 'field_2_21_7': '0',
 'field_2_22_0': '0',
 'field_2_22_1': '0',
 'field_2_22_2': '0',
 'field_2_22_3': '0',
 'field_2_22_4': '0',
 'field_2_22_5': '0',
 'field_2_22_6': '0',
 'field_2_22_7': '0',
 'field_2_23_0': '0',
 'field_2_23_1': '0',
 'field_2_23_2': '0',
 'field_2_23_3': '0',
 'field_2_23_4': '0',
 'field_2_23_5': '0',
 'field_2_23_6': '0',
 'field_2_23_7': '0',
 'field_2_24_0': '0',
 'field_2_24_1': '0',
 'field_2_24_2': '0',
 'field_2_24_3': '0',
 'field_2_24_4': '0',
 'field_2_24_5': '0',
 'field_2_24_6': '0',
 'field_2_24_7': '0',
 'field_2_25_0': '0',
 'field_2_25_1': '0',
 'field_2_25_2': '0',
 'field_2_25_3': '0',
 'field_2_25_4': '0',
 'field_2_25_5': '0',
 'field_2_25_6': '0',
 'field_2_25_7': '0',
 'field_2_26_0': '0',
 'field_2_26_1': '0',
 'field_2_26_2': '0',
 'field_2_26_3': '0',
 'field_2_26_4': '0',
 'field_2_26_5': '0',
 'field_2_26_6': '0',
 'field_2_26_7': '0',
 'field_2_2_0': '4.00',
 'field_2_2_1': '4.00',
 'field_2_2_2': '4.00',
 'field_2_2_3': '4.00',
 'field_2_2_4': '4.00',
 'field_2_2_5': '4.00',
 'field_2_2_6': '4.00',
 'field_2_2_7': '4.00',
 'field_2_3_0': '4.00',
 'field_2_3_1': '4.00',
 'field_2_3_2': '4.00',
 'field_2_3_3': '4.00',
 'field_2_3_4': '4.00',
 'field_2_3_5': '4.00',
 'field_2_3_6': '4.00',
 'field_2_3_7': '4.00',
 'field_2_4_0': '0.80',
 'field_2_4_1': '0.80',
 'field_2_4_2': '0.80',
 'field_2_4_3': '0.80',
 'field_2_4_4': '0.80',
 'field_2_4_5': '0.80',
 'field_2_4_6': '0.80',
 'field_2_4_7': '0.80',
 'field_2_5_0': '33000000',
 'field_2_5_1': '33000000',
 'field_2_5_2': '33000000',
 'field_2_5_3': '33000000',
 'field_2_5_4': '33000000',
 'field_2_5_5': '33000000',
 'field_2_5_6': '33000000',
 'field_2_5_7': '33000000',
 'field_2_6_0': '1',
 'field_2_6_1': '1',
 'field_2_6_2': '1',
 'field_2_6_3': '1',
 'field_2_6_4': '1',
 'field_2_6_5': '1',
 'field_2_6_6': '1',
 'field_2_6_7': '1',
 'field_2_7_0': '1',
 'field_2_7_1': '1',
 'field_2_7_2': '1',
 'field_2_7_3': '1',
 'field_2_7_4': '1',
 'field_2_7_5': '1',
 'field_2_7_6': '1',
 'field_2_7_7': '1',
 'field_2_8_0': '3.2',
 'field_2_8_1': '3.2',
 'field_2_8_2': '3.2',
 'field_2_8_3': '3.2',
 'field_2_8_4': '3.2',
 'field_2_8_5': '3.2',
 'field_2_8_6': '3.2',
 'field_2_8_7': '3.2',
 'field_2_9_0': '0',
 'field_2_9_1': '0',
 'field_2_9_2': '0',
 'field_2_9_3': '0',
 'field_2_9_4': '0',
 'field_2_9_5': '0',
 'field_2_9_6': '0',
 'field_2_9_7': '0',
  'field_4_1_0': '91860',
  'field_4_1_1': '91860',
  'field_4_abutment_mass': '150',
  'field_4_2_0': '61240',
  'field_4_2_1': '61240',
  'field_4_3_0': '3.5',
  'field_4_3_1': '3.5',
  'field_4_4_0': '0.2',
  'field_4_4_1': '0.2',
  'field_4_5_0': '0.0',
  'field_4_5_1': '0.0',
  'field_4_6_0': '2',
  'field_4_6_1': '2'
  }
})(WizardFormFirstPage)



export default WizardFormFirstPage