import React from 'react'

const renderTextarea = ({ input, label, customClass, infoText, cols, rows, disabled, meta: { touched, error } }) => (
  <div className={customClass}>
    <label>{label}</label>
    <div className="textareainfo info-trigger"><span >i</span><div className="info-message" dangerouslySetInnerHTML={{__html:infoText}}></div></div>
    <div className="wizard-input-container">
      <textarea {...input} cols={cols} rows={rows} disabled={disabled} />
      <div className="error-message">{touched && error && error.split("||").map(x => <span>{x}</span>)}</div>
    </div>
  </div>
)

export default renderTextarea