//helper functions

//is a number
function isNumber(x) {
    return !isNaN(x) && x.trim() !== "";
}

//get how many items are valid numbers in an array of elements
function countNumbersInArray(list) {
    return list.filter(x => isNumber(x)).length;
}

//check how many decimal points a number has
function countPlaces(num) {
    var text = num.toString();
    var index = text.indexOf(".");
    return index == -1 ? 0 : (text.length - index - 1);
}

//get number of occurances from a character
function countCharacter(str, char) {
    return str.split(char).length-1
}

//error messages
function error_msg_required(value) {
    if ( !value && value !== 0 ) { //we need to check for zero. Javascript returns true for !0 in case of 0 is the input
        return "Required";
    }
    return '';
}

function error_msg_numberRange(value, min, max) {

    if ( value == null ) {
        return `Must be between ${min} and ${max}`
    }
    if ( min !== null && max !== null ) {
        if ( !(value >= min && value <= max) ) {
            return `Must be between ${min} and ${max}`;
        }
    } else if ( min !== null ) {
        if ( value < min ) {
            return `Must be greater than ${min}`; 
        }
    } else if ( max !== null ) {
        if ( value > max ) {
            return `Must be less than ${max}`; 
        }
    }
    return '';
    
}

function error_msg_sameFrequencyAs(current, other) {
    if ( current == null ) {
        return `You need ${other} numeric values. You added 0`;
    }
    current = current.toString();
    if ( current.indexOf(";") !== -1  ) {
        const nr_current = countNumbersInArray(current.split(';'));
    
        if ( nr_current !== other ) {
            return `You need ${other} numeric values. You added ${nr_current}`;
        }
    }
    return '';
}

function error_msg_onlyNumbers(value) {
    if ( value == null ) {
        return "Only numbers are allowed";
    }
    value = value.toString();
    if ( value.indexOf(";") !== -1  ) {
        if ( value.split(';').some( x => !isNumber(x)) ) {
            return "Only numbers are allowed";
        }
    } else if ( !isNumber(value) ) {
        return "Only numbers are allowed";
    }
    return '';    
}

function error_msg_maxNumberOfDecimals(value, nr) {
    if ( value == null ) {
        return `Maximum number of decimals is ${nr}`;
    }
    value = value.toString();
    if ( value.indexOf(";") !== -1  ) {
        if ( value.split(';').some( x => countPlaces(x) > nr) ) {
            return `Maximum number of decimals is ${nr}`;
        }
    } else if ( countPlaces(value) > nr ) {
        return `Maximum number of decimals is ${nr}`;
    }
    return '';
}

function error_fasma_two_numbers_per_line(rows) {
    if ( rows == null ){
        return 'Two numbers per line are allowed'
    }
    rows = rows.split(/\r\n|\r|\n/g);
    if ( rows.some( x => countCharacter(x, ';') !== 1 )) {
        return 'Two number per line are allowed'
    }
    return '';
}

function error_fasma_only_numbers(rows) {
    if ( rows == null ){
        return 'Only numbers are allowed'
    }
    rows = rows.split(/\r\n|\r|\n/g);
    if ( rows.some( x => error_msg_onlyNumbers(x) !== '' )) {
        return 'Only numbers are allowed'
    }
    return '';
}


const validate = (values, props) => {
    const errors = {};
    let temp = '';
    let rules = [];

    
    //STEP 1: Arithmos Anoigmaton
    // if ( values.arithmos_anoigmaton ) {
            rules = [error_msg_required(values.arithmos_anoigmaton)
                    ,error_msg_numberRange(values.arithmos_anoigmaton, 1, 50)
                    ,error_msg_maxNumberOfDecimals(values.arithmos_anoigmaton, 0)
                ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.arithmos_anoigmaton = temp.reduce((y, current) => y + '||' + current);
        }
    // }


    //STEP 1: Mhkh Anoigmaton
    // if ( values.mhkh_anoigmaton ) {
        rules = [error_msg_required(values.mhkh_anoigmaton)
                ,error_msg_sameFrequencyAs(values.mhkh_anoigmaton, values.arithmos_anoigmaton)
                ,error_msg_maxNumberOfDecimals(values.mhkh_anoigmaton, 2)
                ,error_msg_onlyNumbers(values.mhkh_anoigmaton)
            ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.mhkh_anoigmaton = temp.reduce((y, current) => y + '||' + current);
        }
    // }

    //STEP 1: Diakritopoihsh Anoigmaton
    // if ( values.diakritopoihsh_anoigmaton ) {
        rules = [error_msg_required(values.diakritopoihsh_anoigmaton)
                ,error_msg_sameFrequencyAs(values.diakritopoihsh_anoigmaton, values.arithmos_anoigmaton)
                ,error_msg_maxNumberOfDecimals(values.diakritopoihsh_anoigmaton, 2)
                ,error_msg_onlyNumbers(values.diakritopoihsh_anoigmaton)
            ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.diakritopoihsh_anoigmaton = temp.reduce((y, current) => y + '||' + current);
        }
    // }

    //STEP 1: Omoiomorfo Fortio
    // if ( values.omoiomorfo_fortio ) {
        rules = [error_msg_required(values.omoiomorfo_fortio)
                ,error_msg_sameFrequencyAs(values.omoiomorfo_fortio, values.arithmos_anoigmaton)
                ,error_msg_maxNumberOfDecimals(values.omoiomorfo_fortio, 2)
                ,error_msg_onlyNumbers(values.omoiomorfo_fortio)
            ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.omoiomorfo_fortio = temp.reduce((y, current) => y + '||' + current);
        }
    // }

    //STEP 1: Ypsoi Vathron
    // if ( values.ypsoi_vathron ) {
        rules = [error_msg_required(values.ypsoi_vathron)
                ,error_msg_sameFrequencyAs(values.ypsoi_vathron, values.arithmos_anoigmaton - 1)
                ,error_msg_maxNumberOfDecimals(values.ypsoi_vathron, 2)
                ,error_msg_onlyNumbers(values.ypsoi_vathron)
            ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 && values.arithmos_anoigmaton > 1) {
            errors.ypsoi_vathron = temp.reduce((y, current) => y + '||' + current);
        }
    // }

    //STEP 1: Geometria Katastromatos
    // if ( values.geometria_katastromatos ) {
        rules = [error_msg_required(values.geometria_katastromatos)
                ,error_msg_sameFrequencyAs(values.geometria_katastromatos, 9)
                ,error_msg_maxNumberOfDecimals(values.geometria_katastromatos, 2)
                ,error_msg_onlyNumbers(values.geometria_katastromatos)
            ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.geometria_katastromatos = temp.reduce((y, current) => y + '||' + current);
        }
    // }

    //STEP 1: Aposvesi
    // if ( values.aposvesi ) {
        rules = [error_msg_required(values.aposvesi)           
                ,error_msg_maxNumberOfDecimals(values.aposvesi, 2)
                ,error_msg_numberRange(values.aposvesi, 0, 1)
            ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.aposvesi = temp.reduce((y, current) => y + '||' + current);
        }
    // }

    

    //STEP 1: Stathmes Vlavis
    // if ( values.stathmes_vlavis ) {
        rules = [error_msg_required(values.stathmes_vlavis)        
                ,error_msg_sameFrequencyAs(values.stathmes_vlavis, 4)
                ,error_msg_maxNumberOfDecimals(values.stathmes_vlavis, 2)
                ,error_msg_onlyNumbers(values.stathmes_vlavis)
            ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.stathmes_vlavis = temp.reduce((y, current) => y + '||' + current);
        }
    // }

    //STEP 1: fasma_ec8

        
        rules = [error_msg_required(values.fasma_ec8)
            ,error_fasma_two_numbers_per_line(values.fasma_ec8)
            ,error_fasma_only_numbers(values.fasma_ec8)
        ]
        
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.fasma_ec8 = temp.reduce((y, current) => y + '||' + current);
        }   


    
    //STEP 2
    //General
    //Polistila Arithmos Stilon
    // if ( values.polistila_arithmos_stilon ) {
        rules = [error_msg_required(values.polistila_arithmos_stilon)           
            ,error_msg_maxNumberOfDecimals(values.polistila_arithmos_stilon, 0)
            ,error_msg_numberRange(values.polistila_arithmos_stilon, 1, null)
        ]
    temp = rules.filter(x => x !== '')
    if ( temp.length > 0 ) {
        errors.polistila_arithmos_stilon = temp.reduce((y, current) => y + '||' + current);
    }
// }

    //Polistila Apostasi Stilon
    // if ( values.polistila_apostasis_stilon ) {
        rules = [error_msg_required(values.polistila_apostasis_stilon)        
                ,error_msg_sameFrequencyAs(values.polistila_apostasis_stilon, values.polistila_arithmos_stilon - 1)
                ,error_msg_maxNumberOfDecimals(values.polistila_apostasis_stilon, 2)
                ,error_msg_onlyNumbers(values.polistila_apostasis_stilon)
            ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.polistila_apostasis_stilon = temp.reduce((y, current) => y + '||' + current);
        }
    // }

        //D1P
        rules = [error_msg_required(values.d1p)           
            ,error_msg_maxNumberOfDecimals(values.d1p, 3)
            ,error_msg_onlyNumbers(values.d1p)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d1p = temp.reduce((y, current) => y + '||' + current);
        }

        //D2P
        rules = [error_msg_required(values.d2p)           
            ,error_msg_maxNumberOfDecimals(values.d2p, 3)
            ,error_msg_onlyNumbers(values.d2p)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d2p = temp.reduce((y, current) => y + '||' + current);
        }

        //D3P
        rules = [error_msg_required(values.d3p)           
            ,error_msg_maxNumberOfDecimals(values.d3p, 3)
            ,error_msg_onlyNumbers(values.d3p)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d3p = temp.reduce((y, current) => y + '||' + current);
        }

        //D4P
        rules = [error_msg_required(values.d4p)           
            ,error_msg_maxNumberOfDecimals(values.d4p, 3)
            ,error_msg_onlyNumbers(values.d4p)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d4p = temp.reduce((y, current) => y + '||' + current);
        }

        //D1B
        rules = [error_msg_required(values.d1b)           
            ,error_msg_maxNumberOfDecimals(values.d1b, 3)
            ,error_msg_onlyNumbers(values.d1b)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d1b = temp.reduce((y, current) => y + '||' + current);
        }

        //D2B
        rules = [error_msg_required(values.d2b)           
            ,error_msg_maxNumberOfDecimals(values.d2b, 3)
            ,error_msg_onlyNumbers(values.d2b)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d2b = temp.reduce((y, current) => y + '||' + current);
        }

        //D3B
        rules = [error_msg_required(values.d3b)           
            ,error_msg_maxNumberOfDecimals(values.d3b, 3)
            ,error_msg_onlyNumbers(values.d3b)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d3b = temp.reduce((y, current) => y + '||' + current);
        }

        //D4B
        rules = [error_msg_required(values.d4b)           
            ,error_msg_maxNumberOfDecimals(values.d4b, 3)
            ,error_msg_onlyNumbers(values.d4b)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d4b = temp.reduce((y, current) => y + '||' + current);
        }

        //D1A
        rules = [error_msg_required(values.d1a)           
            ,error_msg_maxNumberOfDecimals(values.d1a, 3)
            ,error_msg_onlyNumbers(values.d1a)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d1a = temp.reduce((y, current) => y + '||' + current);
        }

        //D2A
        rules = [error_msg_required(values.d2a)           
            ,error_msg_maxNumberOfDecimals(values.d2a, 3)
            ,error_msg_onlyNumbers(values.d2a)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d2a = temp.reduce((y, current) => y + '||' + current);
        }

        //D3A
        rules = [error_msg_required(values.d3a)           
            ,error_msg_maxNumberOfDecimals(values.d3a, 3)
            ,error_msg_onlyNumbers(values.d3a)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d3a = temp.reduce((y, current) => y + '||' + current);
        }

        //D4A
        rules = [error_msg_required(values.d4a)           
            ,error_msg_maxNumberOfDecimals(values.d4a, 3)
            ,error_msg_onlyNumbers(values.d4a)
        ]
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors.d4a = temp.reduce((y, current) => y + '||' + current);
        }
        


    for( let subField = 0; subField < values.arithmos_anoigmaton - 1; subField++) {
        
        rules = [error_msg_required(values[`field_2_1_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_1_${subField}`], 4)
            ,error_msg_onlyNumbers(values[`field_2_1_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_1_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_2_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_2_${subField}`], 4)
            ,error_msg_onlyNumbers(values[`field_2_2_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_2_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_3_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_3_${subField}`], 4)
            ,error_msg_onlyNumbers(values[`field_2_3_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_3_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_4_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_4_${subField}`], 4)
            ,error_msg_onlyNumbers(values[`field_2_4_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_4_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_5_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_5_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_5_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_5_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_6_${subField}`])        
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_6_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_7_${subField}`])        
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_7_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_8_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_8_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_8_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_8_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_9_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_9_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_9_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_9_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_10_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_10_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_10_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_10_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_11_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_11_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_11_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_11_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_12_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_12_${subField}`], 3)
            ,error_msg_onlyNumbers(values[`field_2_12_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_12_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_13_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_13_${subField}`], 4)
            ,error_msg_onlyNumbers(values[`field_2_13_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_13_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_14_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_14_${subField}`], 4)
            ,error_msg_onlyNumbers(values[`field_2_14_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_14_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_15_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_15_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_15_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_15_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_16_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_16_${subField}`], 3)
            ,error_msg_onlyNumbers(values[`field_2_16_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_16_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_17_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_17_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_17_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_17_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_18_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_18_${subField}`], 3)
            ,error_msg_onlyNumbers(values[`field_2_18_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_18_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_19_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_19_${subField}`], 4)
            ,error_msg_onlyNumbers(values[`field_2_19_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_19_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_20_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_20_${subField}`], 4)
            ,error_msg_onlyNumbers(values[`field_2_20_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_20_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_21_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_21_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_21_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_21_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_22_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_22_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_22_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_22_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_23_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_23_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_23_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_23_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_24_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_24_${subField}`], 4)
            ,error_msg_onlyNumbers(values[`field_2_24_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_24_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_25_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_25_${subField}`], 4)
            ,error_msg_onlyNumbers(values[`field_2_25_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_25_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }

        rules = [error_msg_required(values[`field_2_26_${subField}`])        
            ,error_msg_maxNumberOfDecimals(values[`field_2_26_${subField}`], 2)
            ,error_msg_onlyNumbers(values[`field_2_26_${subField}`])
        ]

        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors[`field_2_26_${subField}`] = temp.reduce((y, current) => y + '||' + current);
        }
    }

    //STEP 3

    for( let subField = 0; subField < values.arithmos_anoigmaton + 2; subField++) {

      //  if ( values[`field_3_1_${subField}`] ) {
            rules = [error_msg_required(values[`field_3_1_${subField}`])        
                    ,error_msg_maxNumberOfDecimals(values[`field_3_1_${subField}`], 5)
                    ,error_msg_onlyNumbers(values[`field_3_1_${subField}`])
                ]
            
            temp = rules.filter(x => x !== '')
            if ( temp.length > 0 ) {
                errors[`field_3_1_${subField}`] = temp.reduce((y, current) => y + '||' + current);
            }
      //  }

        // if ( values[`field_3_2_${subField}`] ) {
            rules = [error_msg_required(values[`field_3_2_${subField}`])        
                    ,error_msg_maxNumberOfDecimals(values[`field_3_2_${subField}`], 5)
                    ,error_msg_onlyNumbers(values[`field_3_2_${subField}`])
                ]
            
            temp = rules.filter(x => x !== '')
            if ( temp.length > 0 ) {
                errors[`field_3_2_${subField}`] = temp.reduce((y, current) => y + '||' + current);
            }
        // }

        // if ( values[`field_3_3_${subField}`] ) {
            rules = [error_msg_required(values[`field_3_3_${subField}`])        
                    ,error_msg_maxNumberOfDecimals(values[`field_3_3_${subField}`], 5)
                    ,error_msg_onlyNumbers(values[`field_3_3_${subField}`])
                ]
            
            temp = rules.filter(x => x !== '')
            if ( temp.length > 0 ) {
                errors[`field_3_3_${subField}`] = temp.reduce((y, current) => y + '||' + current);
            }
        // }

        // if ( values[`field_3_4_${subField}`] ) {
            rules = [error_msg_required(values[`field_3_4_${subField}`])        
                    ,error_msg_maxNumberOfDecimals(values[`field_3_4_${subField}`], 5)
                    ,error_msg_onlyNumbers(values[`field_3_4_${subField}`])
                ]
            
            temp = rules.filter(x => x !== '')
            if ( temp.length > 0 ) {
                errors[`field_3_4_${subField}`] = temp.reduce((y, current) => y + '||' + current);
            }
        // }

        // if ( values[`field_3_5_${subField}`] ) {
            rules = [error_msg_required(values[`field_3_5_${subField}`])        
                    ,error_msg_maxNumberOfDecimals(values[`field_3_5_${subField}`], 5)
                    ,error_msg_onlyNumbers(values[`field_3_5_${subField}`])
                ]
            
            temp = rules.filter(x => x !== '')
            if ( temp.length > 0 ) {
                errors[`field_3_5_${subField}`] = temp.reduce((y, current) => y + '||' + current);
            }
        // }

        // if ( values[`field_3_6_${subField}`] ) {
            rules = [error_msg_required(values[`field_3_6_${subField}`])        
                    ,error_msg_maxNumberOfDecimals(values[`field_3_6_${subField}`], 5)
                    ,error_msg_onlyNumbers(values[`field_3_6_${subField}`])
                ]
            
            temp = rules.filter(x => x !== '')
            if ( temp.length > 0 ) {
                errors[`field_3_6_${subField}`] = temp.reduce((y, current) => y + '||' + current);
            }
        // }

        // if ( values[`field_3_8_${subField}`] ) {
            rules = [error_msg_required(values[`field_3_8_${subField}`])        
                ,error_msg_maxNumberOfDecimals(values[`field_3_8_${subField}`], 4)
                ,error_msg_onlyNumbers(values[`field_3_8_${subField}`])
            ]
            
            temp = rules.filter(x => x !== '')
            if ( temp.length > 0 ) {
                errors[`field_3_8_${subField}`] = temp.reduce((y, current) => y + '||' + current);
            }
        // }
    }


        //STEP 4
        for( let subField = 0; subField < 2; subField++) {

            // if ( values[`field_4_1_${subField}`] ) {
                rules = [error_msg_required(values[`field_4_1_${subField}`])        
                        ,error_msg_maxNumberOfDecimals(values[`field_4_1_${subField}`], 2)
                        ,error_msg_onlyNumbers(values[`field_4_1_${subField}`])
                    ]
                
                temp = rules.filter(x => x !== '')
                if ( temp.length > 0 ) {
                    errors[`field_4_1_${subField}`] = temp.reduce((y, current) => y + '||' + current);
                }
            // }
    
            // if ( values[`field_4_2_${subField}`] ) {
                rules = [error_msg_required(values[`field_4_2_${subField}`])        
                        ,error_msg_maxNumberOfDecimals(values[`field_4_2_${subField}`], 2)
                        ,error_msg_onlyNumbers(values[`field_4_2_${subField}`])
                    ]
                
                temp = rules.filter(x => x !== '')
                if ( temp.length > 0 ) {
                    errors[`field_4_2_${subField}`] = temp.reduce((y, current) => y + '||' + current);
                }
            // }
    
            // if ( values[`field_4_3_${subField}`] ) {
                rules = [error_msg_required(values[`field_4_3_${subField}`])        
                        ,error_msg_maxNumberOfDecimals(values[`field_4_3_${subField}`], 2)
                        ,error_msg_onlyNumbers(values[`field_4_3_${subField}`])
                    ]
                
                temp = rules.filter(x => x !== '')
                if ( temp.length > 0 ) {
                    errors[`field_4_3_${subField}`] = temp.reduce((y, current) => y + '||' + current);
                }
            // }
    
            // if ( values[`field_4_4_${subField}`] ) {
                rules = [error_msg_required(values[`field_4_4_${subField}`])        
                        ,error_msg_maxNumberOfDecimals(values[`field_4_4_${subField}`], 3)
                        ,error_msg_onlyNumbers(values[`field_4_4_${subField}`])
                    ]
                
                temp = rules.filter(x => x !== '')
                if ( temp.length > 0 ) {
                    errors[`field_4_4_${subField}`] = temp.reduce((y, current) => y + '||' + current);
                }
            // }
    
            // if ( values[`field_4_5_${subField}`] ) {
                rules = [error_msg_required(values[`field_4_5_${subField}`])        
                        ,error_msg_maxNumberOfDecimals(values[`field_4_5_${subField}`], 3)
                        ,error_msg_onlyNumbers(values[`field_4_5_${subField}`])
                    ]
                
                temp = rules.filter(x => x !== '')
                if ( temp.length > 0 ) {
                    errors[`field_4_5_${subField}`] = temp.reduce((y, current) => y + '||' + current);
                }
            // }
        }
        rules = [error_msg_required(values['field_4_abutment_mass'])             
                        ,error_msg_onlyNumbers(values['field_4_abutment_mass'])
                    ]
                
        temp = rules.filter(x => x !== '')
        if ( temp.length > 0 ) {
            errors['field_4_abutment_mass'] = temp.reduce((y, current) => y + '||' + current);
        }
    return errors
  }
export default validate